import moment from 'moment';
import { DATE_FORMAT_SLASH } from 'src/config';
import { emptyDataPlaceholder } from 'src/utils/text';
import { WorkOfDriverCounteragent, WorkOfDriverReport } from './types';
import { ReportTableConfig, TableRow } from '../types';
import { ReportFilterData } from '../../types';

export const title = '3. Звіт про роботу водіїв';

export const getReportDate = (date: string | undefined) => {
  if (!date) {
    return null;
  }

  return moment(date, 'YYYY-MM-DDTHH:mm:ss').format(DATE_FORMAT_SLASH);
};

export const getCounteragentSection = (
  counteragent: WorkOfDriverCounteragent
): TableRow[] => {
  const result: TableRow[] = [
    [
      {
        colSpan: 20,
        value: counteragent.counteragentFullName
      }
    ],
    ...counteragent.reports.map((reportEl, index) => [
      { value: getReportDate(reportEl.date) || index + 1 },
      { value: emptyDataPlaceholder(reportEl.driverName) },
      { value: emptyDataPlaceholder(reportEl.vehicle) },
      { value: emptyDataPlaceholder(reportEl.route) },
      { value: emptyDataPlaceholder(reportEl.departureTime) },
      { value: emptyDataPlaceholder(reportEl.startTimePlan) },
      { value: emptyDataPlaceholder(reportEl.startTimeActual) },
      { value: emptyDataPlaceholder(reportEl.endTimePlan) },
      { value: emptyDataPlaceholder(reportEl.endTimeActual) },
      { value: emptyDataPlaceholder(reportEl.arrivalTime) },
      { value: emptyDataPlaceholder(reportEl.stopsQuantityPlan) },
      { value: emptyDataPlaceholder(reportEl.stopsQuantityActual) },
      { value: emptyDataPlaceholder(reportEl.stopsQuantitySchedule) },
      { value: emptyDataPlaceholder(reportEl.stopsQuantityFulfillment) },
      { value: emptyDataPlaceholder(reportEl.iterationsQuantityPlan) },
      { value: emptyDataPlaceholder(reportEl.iterationsQuantityActual) },
      { value: emptyDataPlaceholder(reportEl.iterationsQuantityFulfillment) },
      { value: emptyDataPlaceholder(reportEl.mileagePlan.toFixed(3)) },
      { value: emptyDataPlaceholder(reportEl.mileageActual.toFixed(3)) },
      { value: emptyDataPlaceholder(reportEl.mileageGeneral.toFixed(3)) }
    ])
  ];

  return result;
};

export const getTableConfig = (
  filterData: ReportFilterData,
  report: WorkOfDriverReport
): ReportTableConfig => {
  return [
    [{ colSpan: 12, value: title }],
    [{ value: 'Дата від' }, { colSpan: 11, value: filterData.dateFrom }],
    [{ value: 'Дата до' }, { colSpan: 11, value: filterData.dateTo }],
    [],
    [
      { rowSpan: 3, value: '№ з/п або дата' },
      { rowSpan: 3, value: 'Водій' },
      { rowSpan: 3, value: 'Транспортний засіб' },
      { rowSpan: 3, value: 'Маршрут' },
      { rowSpan: 3, value: 'Час виїзду з депо, год' },
      { colSpan: 2, value: 'Час початку роботи на маршруті, год' },
      { colSpan: 2, value: 'Час закінчення роботи на маршруті, год' },
      { rowSpan: 3, value: 'Час заїзду в депо, год' },
      { colSpan: 4, value: 'Кількість зупинок' },
      { colSpan: 3, value: 'Кількість кіл' },
      { colSpan: 3, value: 'Пробіг , км' }
    ],
    [
      { rowSpan: 2, value: 'план' },
      { rowSpan: 2, value: 'факт' },
      { rowSpan: 2, value: 'план' },
      { rowSpan: 2, value: 'факт' },
      { rowSpan: 2, value: 'план' },
      { rowSpan: 2, value: 'факт' },
      { rowSpan: 2, value: 'по графіку' },
      { rowSpan: 2, value: '% виконання' },
      { rowSpan: 2, value: 'план' },
      { rowSpan: 2, value: 'факт' },
      { rowSpan: 2, value: '% виконання' },
      { colSpan: 2, value: 'на маршруті' },
      { rowSpan: 2, value: 'загальний' }
    ],
    [{ value: 'план' }, { value: 'факт' }],
    ...report.counteragents.map(getCounteragentSection).flat()
  ];
};
