import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { STATUS_CONFIG } from 'src/constants';
import { ENABLED } from '../../const';

const matchSpaceParams = createMatchSelector('/avl/vehicles/:id/details');

export const selectVehicleId = createSelector(
  matchSpaceParams,
  (match: any) => match?.params.id || ''
);

export const selectVehicleRows = (state: RootState) => {
  const vehicleDetails = get(state, `ui.avl.vehicles.details`);

  return [
    { label: 'Номерний знак', value: vehicleDetails.licensePlate },
    {
      label: 'Модель ТЗ',
      value: vehicleDetails.vehicleModelName
    },
    { label: 'Підрозділ', value: vehicleDetails.counteragentName },
    { label: 'Водій', value: vehicleDetails.driverName },
    { label: 'GPS (IMEI)', value: vehicleDetails.imei },
    {
      label: 'Статус',
      value: STATUS_CONFIG[vehicleDetails.status.toLowerCase()].label
    }
  ];
};

export const selectIsVehicleLoading = (state: RootState) => {
  const loading = get(state, `loading.@VEHICLE_DETAILS/GET_VEHICLE`);
  return isBoolean(loading) ? loading : true;
};

export const selectVehicleLicensePLate = (state: RootState): string =>
  get(state, `ui.avl.vehicles.details.licensePlate`);

export const selectDeleteDisable = (state: RootState) => {
  const { status, hasInstaledEquipment } = get(
    state,
    'ui.avl.vehicles.details'
  );

  return {
    isDisabled: status === ENABLED || hasInstaledEquipment,
    tooltip:
      status === ENABLED
        ? 'Неможливо видалити, оскільки статус активний'
        : 'Неможливо видалити, транспортний засіб має встановлене обладнання'
  };
};
