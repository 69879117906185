import { flatten } from 'lodash';
import { HourlyDeparture } from './types';
import { ReportTableConfig, TableRow } from '../types';
import { ReportFilterData } from '../../types';
import { SUMMARY_ROW_FILL_COLOR } from '../../../../../../../utils/xlsx/getSummaryRowStyles';

export const title = '2. Звіт погодинного випуску автобусів на маршрут';

export const getTableHeadersCount = (report: HourlyDeparture) =>
  2 + report.counteragents[0]?.routes[0]?.timeSpans.length * 2;

export const getTableConfig = (
  filterData: ReportFilterData,
  report: HourlyDeparture
): ReportTableConfig => {
  const times = report.counteragents[0]?.routes[0]?.timeSpans || [];

  const formattedWorkingHours = times.map(
    ({ from, to }) => `з ${from} до ${to}`
  );

  const headersCount = getTableHeadersCount(report);

  return [
    [{ colSpan: headersCount, value: title, styles: { fontStyle: 'bold' } }],
    [
      { value: 'Дата', colSpan: 1, styles: { fontStyle: 'bold' } },
      {
        colSpan: headersCount - 1,
        value: filterData.date,
        styles: { halign: 'left' }
      }
    ] as TableRow,
    [],
    [
      {
        rowSpan: 3,
        value: 'Маршрут',
        styles: { fontStyle: 'bold' }
      },
      {
        colSpan: formattedWorkingHours.length * 2,
        value: 'Години доби',
        styles: { fontStyle: 'bold' }
      },
      {
        rowSpan: 3,
        value: 'Випуск на маршруті, %',
        styles: { fontStyle: 'bold' }
      }
    ] as TableRow,
    formattedWorkingHours.map(value => ({
      value,
      colSpan: 2,
      styles: { fontStyle: 'bold', cellPadding: 1 }
    })) as TableRow,
    [
      ...flatten(
        formattedWorkingHours.map(
          (): TableRow => [
            {
              value: 'план',
              styles: { cellPadding: 1, fontStyle: 'bold' }
            },
            {
              value: 'факт',
              styles: { cellPadding: 1, fontStyle: 'bold' }
            }
          ]
        )
      )
    ],
    ...report.counteragents
      .map((counteragent): TableRow[] => [
        [
          {
            value: counteragent.name,
            colSpan: headersCount,
            styles: { fontStyle: 'bold', fillColor: SUMMARY_ROW_FILL_COLOR }
          }
        ],
        ...counteragent.routes.map(
          (route): TableRow => [
            { value: route.name, styles: {} },
            ...route.timeSpans
              .map(({ planned, fact }) => [{ value: planned }, { value: fact }])
              .flat(),
            { value: route.ratioPerDay }
          ]
        ),
        [
          {
            value: `В середньому за день роботи (по перевізнику ${counteragent.name})`,
            colSpan: headersCount - 1,
            styles: {
              halign: 'left',
              fontStyle: 'bold',
              fillColor: SUMMARY_ROW_FILL_COLOR
            }
          },
          {
            value: counteragent.ratioPerDay,
            styles: { fontStyle: 'bold', fillColor: SUMMARY_ROW_FILL_COLOR }
          }
        ]
      ])
      .flat()
  ].map(config =>
    config.map(cell => ({
      ...cell,
      styles: { halign: 'center', valign: 'middle', ...cell.styles }
    }))
  );
};
