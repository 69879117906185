import { flatten } from 'lodash';
import { ReportFilterData } from '../../types';
import { ReportTableConfig, TableRow } from '../types';
import { MorningEveningRideData, MorningEveningRidesReport } from './types';
import { getMorningEveningRidesColumns } from './utils';

const headers = [
  {
    label: 'Маршрут №'
  },
  {
    label: 'ТЗ'
  },
  {
    label: 'Зупинка'
  },
  {
    label: 'Початок роботи, год',
    children: ['план', 'факт']
  },
  {
    label: 'Закінчення роботи, год',
    children: ['план', 'факт']
  }
];

export const title = '6. Звіт про виконання ранкових та вечірніх рейсів';

type ReportData = Record<string, MorningEveningRideData[]>;

const generateData = (rides: MorningEveningRidesReport): TableRow[] => {
  const data: ReportData = {};

  rides.forEach(ride => {
    if (!data[ride.routeName]) {
      data[ride.routeName] = [];
    }

    data[ride.routeName].push({
      licensePlate: ride.licensePlate,
      busStopName: ride.busStopName,
      busStopCode: ride.busStopCode,
      departure: ride.departure,
      arrival: ride.arrival
    });
  });

  const rows: TableRow[] = [];

  Object.keys(data).forEach(routeName => {
    const columns = getMorningEveningRidesColumns(data[routeName]);

    columns[0].unshift({
      value: routeName,
      rowSpan: data[routeName].length
    });

    rows.push(...columns);
  });

  return rows;
};

export const getTableConfig = (
  filterData: ReportFilterData,
  report: MorningEveningRidesReport
): ReportTableConfig => [
  [{ value: title, colSpan: 7 }],
  [
    { value: 'Перевізник:', colSpan: 2 },
    {
      value: filterData.counteragent,
      colSpan: 5
    }
  ],
  [
    { value: 'Дата:', colSpan: 2 },
    {
      value: filterData.date,
      colSpan: 5
    }
  ],
  [],
  headers.map(header => ({
    value: header.label,
    rowSpan: header.children?.length ? 1 : 2,
    colSpan: header.children?.length || 1
  })),
  flatten(
    headers
      .filter(header => header.children !== undefined)
      .map(header =>
        header.children!.map(child => ({
          value: child
        }))
      )
  ),
  ...generateData(report)
];
