import * as XLSX from 'sheetjs-style';
import { last } from 'lodash';
import getStylesForReportWithTable from '../../utils/getStylesForReportWithTable';
import { boldText, firstRowStyles, summaryRowStyles } from '../const';
import { StyleBase } from '../RoutePerforming/types';
import { WorkOfCarrierReport } from './types';
import {
  getSummaryRowStyles,
  SummaryRow
} from '../../../../../../../utils/xlsx/getSummaryRowStyles';

const styleBase: StyleBase = {
  A1: {
    s: firstRowStyles
  },
  A2: {
    s: {
      ...boldText
    }
  },
  A5: {
    s: {
      ...boldText
    }
  },
  B5: {
    s: {
      ...boldText
    }
  },
  C5: {
    s: {
      ...boldText
    }
  },
  D5: {
    s: {
      ...boldText
    }
  },
  E5: {
    s: {
      ...boldText
    }
  },
  A6: {
    s: {
      ...summaryRowStyles,
      alignment: {
        horizontal: 'center',
        vertical: 'center'
      }
    }
  }
};

const TABLE_START_ADDRESS: XLSX.CellAddress = { r: 4, c: 0 };

export const getStyles = (report: WorkOfCarrierReport) => {
  // table body starts from 6th row
  const summaryRows: SummaryRow[] = [{ row: 6 }];

  report.counteragents.forEach(({ routes }, counteragentIndex) => {
    const isFirstCounteragent = counteragentIndex === 0;

    if (!isFirstCounteragent) {
      // new counteragent
      summaryRows.push({ row: last(summaryRows)!.row + 1 });
    }

    // summary by counteragent
    summaryRows.push({
      row: last(summaryRows)!.row + routes.length + 1
    });
  });

  return getStylesForReportWithTable(
    {
      ...styleBase,
      ...getSummaryRowStyles(summaryRows, 5)
    },
    TABLE_START_ADDRESS
  );
};
