import { flattenDeep } from 'lodash';
import { PdfGenerator, RowItems, TypeTable } from 'src/services/pdfGenerator';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL, DETAILED_DATE_TIME_FORMAT } from 'src/config';
import { EquipmentTypeEnum, equipmentTypes } from 'src/constants';
import { installationColumnsPdf, installationDocumentPdf } from '../const';
import {
  EquipmentDetail,
  InstallationDetailsState
} from '../InstallationDetailsView/types';
import { EquipmentStateStatusEnum } from '../../tracking/const';
import { SAVE_DOCUMENT_UTC_OFFSET_MINUTES } from '../../const';

export function generatePdfOfInstallation(
  data: InstallationDetailsState
): PdfGenerator {
  const installationData = {
    ...data,
    modifiedAtUtc: utcToLocal(
      data.modifiedAtUtc,
      DETAILED_DATE_TIME_FORMAT,
      SAVE_DOCUMENT_UTC_OFFSET_MINUTES
    )
  };

  const equipments = flattenDeep(
    installationData.equipments.map((item: EquipmentDetail) => {
      const { supplements, ...obj } = item;
      const state =
        EquipmentStateStatusEnum[
          item.state as keyof typeof EquipmentStateStatusEnum
        ];

      if (supplements?.length) {
        return [
          {
            ...obj,
            state
          },
          ...supplements.map(suppl => {
            return {
              name: suppl
            };
          })
        ];
      }

      return { ...obj, state };
    })
  );

  let title = '';
  if (installationData.equipmentGroupId === EquipmentTypeEnum.Mobile) {
    title = `${installationData.licensePlate}`;
  }

  const installationDocument: RowItems[] = [];

  if (
    installationData.equipmentGroupName ===
      equipmentTypes[EquipmentTypeEnum.Stationary] ||
    installationData.equipmentGroupName ===
      equipmentTypes[EquipmentTypeEnum.Inspector]
  ) {
    installationDocumentPdf.forEach(
      item =>
        !['licensePlate', 'vehicleModelName', 'vehicleCategoryName'].includes(
          item.value
        ) && installationDocument.push(item)
    );
  }

  if (
    installationData.equipmentGroupName ===
    equipmentTypes[EquipmentTypeEnum.Mobile]
  ) {
    installationDocumentPdf.forEach(
      item => item.value !== 'employeeName' && installationDocument.push(item)
    );
  }

  return new PdfGenerator(
    `Монтаж №${installationData.documentNumber ||
      ''} ${title} ${installationData.installingEmployeeName ||
      ''} від ${utcToLocal(
      installationData.installationDate,
      DATE_FORMAT_FULL
    )}`,
    undefined,
    { orientation: 'l' }
  )
    .setHeader(
      `Акт монтажу №${installationData.documentNumber || ''} від ${utcToLocal(
        installationData.installationDate,
        DATE_FORMAT_FULL
      )}`,
      true
    )
    .setTable(installationDocument, installationData, TypeTable.InfoTable)
    .setHeader('Встановлене/Видане Обладнання', false)
    .setTable(installationColumnsPdf, equipments, TypeTable.ListTable);
}
