import { CellAddress } from 'sheetjs-style';
import { FilterData } from 'src/views/avl/reports/ReportsDownloadView/components/types';
import { isDateValid, isValidEndDate } from 'src/utils/date';
import { ReportType } from '../types';

import * as HourlyDeparture from './ReportsTables/HourlyDeparture';
import * as BusWorkOnRoute from './ReportsTables/BusWorkOnRoute';
import * as WorkOfDriver from './ReportsTables/WorkOfDriver';
import * as WorkOfCarrier from './ReportsTables/WorkOfCarrier';
import * as PresenceOnRouteNetwork from './ReportsTables/PresenceOnRouteNetwork';
import * as MorningEveningRides from './ReportsTables/MorningEveningRides';
import * as RoutePerforming from './ReportsTables/RoutePerforming';
import * as GraphicityPerformance from './ReportsTables/GraphicityPerformance';
import * as UnmappedGPSData from './ReportsTables/UnmappedGPSData';
import { SheetDimensions } from '../../../../../utils/downloadXlsxFileFromTable';

export type GetStylesType = (
  sheetEndAddress: CellAddress
) => Record<string, unknown>;

interface ReportsSettings {
  title: string;
  config: any;
  styles: any;
  dimensions?: SheetDimensions;
}

export const reportSettings: Record<ReportType, ReportsSettings> = {
  [ReportType.busWorkOnRouteReport]: BusWorkOnRoute,
  [ReportType.hourlyDeparture]: HourlyDeparture,
  [ReportType.workOfDriver]: WorkOfDriver,
  [ReportType.workOfCarrier]: WorkOfCarrier,
  [ReportType.graphicityPerformance]: GraphicityPerformance,
  [ReportType.morningAndEveningRide]: MorningEveningRides,
  [ReportType.routePerforming]: RoutePerforming,
  [ReportType.presenceOnRouteNetwork]: PresenceOnRouteNetwork,
  [ReportType.unmappedGPSData]: UnmappedGPSData
};

const dateRangeValidator = ({ dateTo, dateFrom }: FilterData) => {
  const dateToValid = isDateValid(dateTo);
  const dateFromValid = isDateValid(dateFrom);
  const dateRangeValid =
    dateTo === dateFrom || isValidEndDate(dateFrom, dateTo);

  return dateFromValid && dateToValid && dateRangeValid;
};

const routeValidator = ({ counteragent, date, route }: FilterData) =>
  counteragent && isDateValid(date) && route;

const counteragentValidator = ({ counteragent, date }: FilterData) =>
  counteragent !== null && isDateValid(date);

const periodFiltersValidator = (values: FilterData) => {
  if (values.counteragent !== null) {
    if (values.dateFrom && !values.dateTo) {
      return isDateValid(values.dateFrom);
    }
    if (values.dateFrom && values.dateTo) {
      return dateRangeValidator(values);
    }
  }

  return false;
};

export const validationMap: Record<
  ReportType,
  (filters: FilterData) => boolean
> = {
  [ReportType.busWorkOnRouteReport]: periodFiltersValidator,
  [ReportType.hourlyDeparture]: counteragentValidator,

  [ReportType.workOfDriver]: periodFiltersValidator,
  [ReportType.workOfCarrier]: counteragentValidator,
  [ReportType.graphicityPerformance]: routeValidator,
  [ReportType.morningAndEveningRide]: counteragentValidator,
  [ReportType.routePerforming]: values =>
    !!values.counteragent && !!values.route && dateRangeValidator(values),
  [ReportType.presenceOnRouteNetwork]: routeValidator,
  [ReportType.unmappedGPSData]: dateRangeValidator
};

export const REPORTS_WITH_PERIOD = [
  ReportType.busWorkOnRouteReport,
  ReportType.workOfDriver
] as const;
