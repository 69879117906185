import moment from 'moment';
import { DATE_FORMAT_SLASH } from 'src/config';
import { emptyDataPlaceholder } from 'src/utils/text';
import { ReportFilterData } from '../../types';
import { ReportTableConfig } from '../types';
import { Ride, RoutePerformingReport } from './types';

const convertRidesToValues = (rides: Ride[]): ReportTableConfig =>
  rides.map(ride => [
    { value: moment(ride.date).format(DATE_FORMAT_SLASH) },
    { value: ride.licensePlate },
    { value: ride.departureNumber },
    { value: ride.totalRides.toFixed(2) },
    { value: ride.ridesCompleted.toFixed(2) },
    { value: ride.ridesCompletenessRatio.toFixed(2) },
    { value: ride.ridesFailed.toFixed(2) },
    { value: ride.ridesFailureRatio.toFixed(2) }
  ]);

export const title = '7. Звіт про виконання маршруту';

export const getTableConfig = (
  filterData: ReportFilterData,
  report: RoutePerformingReport
): ReportTableConfig => [
  [{ value: title, colSpan: 8 }],
  [{ value: 'Перевізник' }, { value: filterData.counteragent, colSpan: 7 }],
  [
    { value: 'Маршрут' },
    { value: `${filterData.routeNumber}, ${filterData.route}`, colSpan: 7 }
  ],
  [{ value: 'Дата від' }, { value: filterData.dateFrom, colSpan: 7 }],
  [{ value: 'Дата до' }, { value: filterData.dateTo, colSpan: 7 }],
  [],
  [
    { value: 'Дата' },
    { value: 'Транспортний засіб' },
    { value: 'Випуск' },
    { value: 'Заплановано їздок' },
    { value: 'Виконано їздок' },
    { value: 'Виконано їздок, %' },
    { value: 'Не виконано їздок' },
    { value: 'Не виконано їздок, %' }
  ],
  ...convertRidesToValues(report.rides),
  [
    { value: 'Всього за період', colSpan: 3 },
    { value: emptyDataPlaceholder(report.ridesCount.toFixed(2)) },
    { value: emptyDataPlaceholder(report.totalRidesCompleted.toFixed(2)) },
    {
      value: emptyDataPlaceholder(report.completenessRatio.toFixed(2))
    },
    { value: emptyDataPlaceholder(report.totalRidesFailed.toFixed(2)) },
    { value: emptyDataPlaceholder(report.failureRatio.toFixed(2)) }
  ]
];
