import { BreakdownStateEnum } from '@eticket/asset-management-api-contracts';
import { colors } from '@material-ui/core';
import { IconName } from 'src/components/Tables/types';
import { LabelColors } from 'src/components/Labels/Label/types';

export {
  MAIN_MENU,
  NAV_BAR_HEADERS,
  NAV_BAR_ITEMS,
  NAV_BAR_SUB_HEADERS
} from './menuItems';
export { THEMES } from './themes';

export enum StatusEnum {
  Draft = 'Draft',
  Fixed = 'Fixed',
  Completed = 'Completed'
}

export const STATUS_CONFIG: Record<
  string,
  { label: string; color: LabelColors }
> = {
  enabled: {
    color: 'success',
    label: 'Активний'
  },
  approved: {
    color: 'success',
    label: 'Затверджено'
  },
  fixed: {
    color: 'success',
    label: 'Зафіксований'
  },
  draft: {
    color: 'draft',
    label: 'Чернетка'
  },
  disabled: {
    color: 'error',
    label: 'Не активний'
  },
  completed: {
    color: 'success',
    label: 'Зафіксований'
  },
  norm: {
    color: 'success',
    label: 'Норма'
  },
  calculated: {
    color: 'success',
    label: 'Обраховано'
  },
  imposed: {
    color: 'draft',
    label: 'Накладено'
  }
};

export const TABLE_ACTIONS_CONFIG = {
  CANCEL_REGISTRATION: 'cancelRegistration',
  SCAN: 'scan',
  EDIT: 'edit',
  DOWNLOAD: 'download',
  DELETE: 'delete',
  HISTORY: 'history',
  COPY: 'copy',
  RESEND: 'resend',
  REVOKE: 'revoke',
  RECALCULATE: 'recalculate'
};

export const TABLE_ACTIONS: {
  [key: string]: {
    tooltip: string;
    disabledTooltip: string;
    icon: IconName;
  };
} = {
  [TABLE_ACTIONS_CONFIG.SCAN]: {
    tooltip: 'Сканувати',
    disabledTooltip: '',
    icon: 'Maximize'
  },
  [TABLE_ACTIONS_CONFIG.EDIT]: {
    tooltip: 'Редагувати',
    disabledTooltip: 'Неможливо редагувати',
    icon: 'Edit'
  },
  [TABLE_ACTIONS_CONFIG.DOWNLOAD]: {
    tooltip: 'Завантажити',
    disabledTooltip: '',
    icon: 'Download'
  },
  [TABLE_ACTIONS_CONFIG.DELETE]: {
    tooltip: 'Видалити',
    disabledTooltip: 'Неможливо видалити',
    icon: 'Trash2'
  },
  [TABLE_ACTIONS_CONFIG.HISTORY]: {
    tooltip: 'Історія змін',
    disabledTooltip: '',
    icon: 'Clock'
  },
  [TABLE_ACTIONS_CONFIG.COPY]: {
    tooltip: 'Копіювати',
    disabledTooltip: '',
    icon: 'Copy'
  },
  [TABLE_ACTIONS_CONFIG.RESEND]: {
    tooltip: 'Надіслати',
    disabledTooltip: '',
    icon: 'Mail'
  },
  [TABLE_ACTIONS_CONFIG.REVOKE]: {
    tooltip: 'Вимкнути',
    disabledTooltip: '',
    icon: 'Power'
  },
  [TABLE_ACTIONS_CONFIG.RECALCULATE]: {
    tooltip: 'Нарахувати',
    disabledTooltip: 'Неможливо нарахувати',
    icon: 'RefreshCw'
  }
};

export const equipmentStateMap = {
  [BreakdownStateEnum.Norm]: 'Норма',
  [BreakdownStateEnum.Inspection]: 'Інспекція',
  [BreakdownStateEnum.Suspicious]: 'Підозрілий',
  [BreakdownStateEnum.Substitute]: 'Заміна',
  [BreakdownStateEnum.Repair]: 'Ремонт',
  Uninstalled: 'Демонтований'
};

export const equipmentStateIdByName = {
  [BreakdownStateEnum.Norm]: 0,
  [BreakdownStateEnum.Inspection]: 1,
  [BreakdownStateEnum.Suspicious]: 2,
  [BreakdownStateEnum.Substitute]: 3,
  [BreakdownStateEnum.Repair]: 4
};

export const colorStateMap: string[] = [
  colors.green[600],
  colors.yellow[600],
  colors.yellow[900],
  colors.blue[600],
  colors.red[600],
  colors.common.black,
  colors.common.black
];

export const equipmentStateDescriptions: Record<string, string> = {
  [equipmentStateMap[BreakdownStateEnum.Inspection]]:
    'встановлюється користувачем в документах Інспекція та Несправність. Може бути використаний з метою відзначення обладнання як такого, що потребує інспекції при створенні документу несправності.',
  [equipmentStateMap[BreakdownStateEnum.Suspicious]]:
    'встановлюється користувачем в документах Інспекція та Несправність. Може бути використаний з метою відзначення обладнання, що функціонує з незрузімілими відхиленнями та потребує інспекції, при створенні документу несправності.',
  [equipmentStateMap[BreakdownStateEnum.Substitute]]:
    'встановлюється користувачем в документах Інспекція та Несправність. Може бути використана з метою відзначення обладнання, що працює, але усе одно підлягає заміні.',
  [equipmentStateMap[BreakdownStateEnum.Repair]]:
    'встановлюється користувачем в документах Інспекція та Несправність. Може бути використаний за результатами проведеної інспекції чи за даними з зовнішніх систем.'
};

export const equipmentColorStateMap = {
  [BreakdownStateEnum.Norm]: colors.green[600],
  [BreakdownStateEnum.Inspection]: colors.yellow[600],
  [BreakdownStateEnum.Suspicious]: colors.yellow[900],
  [BreakdownStateEnum.Substitute]: colors.blue[600],
  [BreakdownStateEnum.Repair]: colors.red[600]
};

export const disabledFormStyles = {
  '& div.MuiFormControl-root label': {
    color: colors.grey[500]
  },
  '& div.MuiFormControl-root div': {
    color: colors.grey[500],
    pointerEvents: 'none'
  },
  '& label.MuiFormControlLabel-root': {
    color: colors.grey[500],
    pointerEvents: 'none'
  },
  '& span.MuiCheckbox-colorSecondary.Mui-checked': {
    color: colors.blueGrey[200]
  },
  '& div.MuiAutocomplete-root': {
    color: colors.grey[500],
    pointerEvents: 'none'
  }
};

export const UNSAFE_CHANGES_MESSAGE =
  'Внесені зміни не будуть збережені. Ви впевнені, що хочете залишити цю сторінку? ';

export const IMPOSSIBLE_DELETE_MESSAGE =
  'Неможливо видалити, оскільки документ опрацьовано, зверніться до адміністратора';

export enum EquipmentTypeEnum {
  Mobile = 1,
  Stationary = 2,
  Inspector = 3
}

export enum EquipmentGroupEnum {
  Mobile = 'Мобільне обладнання',
  Stationary = 'Стаціонарне обладнання',
  Inspector = 'Обладнання для контролерів',
  Warehouse = 'Обладнання на складах'
}

export const equipmentTypes = {
  [EquipmentTypeEnum.Mobile]: 'Мобільне обладнання',
  [EquipmentTypeEnum.Stationary]: 'Стаціонарне обладнання',
  [EquipmentTypeEnum.Inspector]: 'Обладнання для контролерів'
};

export const DAY_IN_MS = 60000 * 60 * 24;

export enum EmployeeProfessionEnum {
  Driver = 1,
  Dispatcher = 2,
  Administrator = 3,
  OperatorAdministrator = 4,
  Analyst = 5,
  Forwarder = 6,
  Mechanic = 7
}

export enum CounteragentTypeId {
  DispatchingOffice = 1,
  Storage = 2,
  Оffice = 3,
  Depot = 4
}

export const DOWNLOAD_LIMITATION_MESSAGE =
  'Деякі документи не були завантажені через встановлені обмеження';
