import { SheetDimensions } from '../../../../../../../utils/downloadXlsxFileFromTable';

const dimensions: SheetDimensions = {
  column: {
    widths: {
      A5: 180,
      B5: 150,
      C5: 155,
      D5: 150,
      E5: 150
    }
  }
};

export default dimensions;
