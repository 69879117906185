import { generateActionCrud } from 'src/components/Guards';
import { MAIN_MENU, NAV_BAR_HEADERS } from 'src/constants';
import { INSPECTIONS_URL } from 'src/routes/assetRoutes';
import { getDateDifference } from 'src/utils/date';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_HEADERS.inspections,
    url: INSPECTIONS_URL
  }
];

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'inspections'
  },
  ['download', 'resendDocument', 'inspectionsAllowEditing']
);

export const INSPECTION_STEPS = ['Інспекція', 'Обладнання'];

export const inspectionFieldsJson = [
  'status',
  'documentNumber',
  'address',
  'inspectionDate',
  'inspectorName',
  'inspectorFullName',
  'equipmentGroup',
  'counteragent',
  'vehicleType',
  'licensePlate',
  'vehicleModel',
  'responsiblePersonFullName',
  'notes',
  'modifiedAtUtc',
  {
    label: 'equipments',
    fields: [
      'index',
      'barcode',
      'name',
      'productType',
      'manufacturer',
      'equipmentStateName',
      'breakdowns'
    ]
  }
];

export enum InspectionStatusEnum {
  Draft = 0,
  Fixed = 1
}

export const MAX_DAYS_AFTER_INSPECTION_CREATE = 40;
export const TOGGLE_DISABLED_TEXT = `Документ зафіксовано понад ${MAX_DAYS_AFTER_INSPECTION_CREATE} днів тому. Редагування заборонене.`;

export const isToggleDateRestricted = (date: string) =>
  getDateDifference(date) >= MAX_DAYS_AFTER_INSPECTION_CREATE;

export const INSPECTION_DETAILS_REDUCER_PATH = 'asset.inspections.details';
export const INSPECTION_EMAIL_DATA_REDUCER_PATH = 'asset.inspections.emailData';

export const INSPECTION_ERROR_MESSAGES: Record<string, string> = {
  notFound:
    'Неможливо зберегти документ. Детальна частина документа не актуальна'
};
