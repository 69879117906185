import { WorkOfDriverReport } from '../types';

export const workOfDriverReportMock: WorkOfDriverReport = {
  counteragents: [
    {
      counteragentId: 1,
      counteragentName: 'Name',
      counteragentFullName: 'FullName',
      reports: [
        {
          date: '21/08/2021T00:00:00',
          driverName: 'Petro M.N.',
          vehicle: 1,
          route: 'Zaliznichniy - Stryiska',
          departureTime: '11:45',
          startTimePlan: '12:00',
          startTimeActual: '12:01',
          endTimePlan: '18:20',
          endTimeActual: '18:15',
          arrivalTime: '18:25',
          iterationsQuantityPlan: 10,
          iterationsQuantityActual: 8,
          iterationsQuantityFulfillment: 80,
          stopsQuantityPlan: 16,
          stopsQuantityActual: 10,
          stopsQuantitySchedule: 89,
          stopsQuantityFulfillment: 76,
          mileagePlan: 1000,
          mileageActual: 999,
          mileageGeneral: 12567
        }
      ]
    }
  ]
};
