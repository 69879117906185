export const ENV = process.env.REACT_APP_ENV;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const OPEN_TRIP_PLANNER = process.env.REACT_APP_OPEN_TRIP_PLANNER;
export const TILE_SERVER = `${process.env.REACT_APP_TILE_SERVER}/styles/basic/{z}/{x}/{y}.png`;
export const PELIAS = process.env.REACT_APP_PELIAS;
export const MQTT_BROKER = process.env.REACT_APP_MQTT_BROKER;
export const DATE_FORMAT_SLASH = 'DD/MM/YYYY';
export const DATETIME_FORMAT_SLASH = 'DD/MM/YYYY HH:mm:ss';
export const DATEPICKER_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'DD.MM.YY';
export const DATE_FORMAT_FULL = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DETAILED_TIME_FORMAT = 'HH:mm:ss';
export const DETAILED_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_TIME_FORMAT = `${DATE_FORMAT_SLASH} ${TIME_FORMAT}`;
export const DETAILED_DATE_TIME_FORMAT = `${DATE_FORMAT_SLASH} ${DETAILED_TIME_FORMAT}`;
