import { createAsyncAction } from 'typesafe-actions';

interface ValidationPayload {
  barcode: string;
  imei: string;
  equipmentId: number;
}

export const validateEquipment = createAsyncAction(
  '@INBOUND/VALIDATE_EQUIPMENT_REQUEST',
  '@INBOUND/VALIDATE_EQUIPMENT_SUCCESS',
  '@INBOUND/VALIDATE_EQUIPMENT_FAILURE'
)<ValidationPayload, undefined, Error>();

export const validateBarcodeIsUsed = createAsyncAction(
  '@INBOUND/VALIDATE_BARCODE_IS_USED_REQUEST',
  '@INBOUND/VALIDATE_BARCODE_IS_USED_SUCCESS',
  '@INBOUND/VALIDATE_BARCODE_IS_USED_FAILURE'
)<string, boolean, Error>();

export const validateIMEIIsUsed = createAsyncAction(
  '@INBOUND/VALIDATE_IMEI_IS_USED_REQUEST',
  '@INBOUND/VALIDATE_IMEI_IS_USED_SUCCESS',
  '@INBOUND/VALIDATE_IMEI_IS_USED_FAILURE'
)<string, boolean, Error>();
