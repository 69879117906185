import * as XLSX from 'sheetjs-style';
import { flatten, range } from 'lodash';

export const getCellAddresses = ({
  s: { c: startColIdx, r: startRowIdx },
  e: { c: endColIdx, r: endRowIdx }
}: XLSX.Range) =>
  flatten(
    range(startRowIdx, endRowIdx + 1)
      .map(XLSX.utils.encode_row)
      .map(rowAddr =>
        range(startColIdx, endColIdx + 1)
          .map(XLSX.utils.encode_col)
          .map(colAddr => colAddr + rowAddr)
      )
  );
