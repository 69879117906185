import * as XLSX from 'sheetjs-style';
import { boldText, firstRowStyles } from '../const';
import getStylesForReportWithTable from '../../utils/getStylesForReportWithTable';

const styleBase = {
  A1: {
    s: firstRowStyles
  },
  A2: {
    s: {
      ...boldText
    }
  },
  A3: {
    s: {
      ...boldText
    }
  }
};

const TABLE_START_ADDRESS: XLSX.CellAddress = { r: 4, c: 0 };
export const getStyles = () =>
  getStylesForReportWithTable(styleBase, TABLE_START_ADDRESS);
