import { createMatchSelector, RouterRootState } from 'connected-react-router';
import { createSelector } from 'reselect';
import { toDetailsTableRow } from 'src/components/Tables/DetailsTable/helpers';
import { RowConfig } from 'src/components/Tables/DetailsTable/types';
import { DATE_FORMAT_SLASH, DATETIME_FORMAT_SLASH } from 'src/config';
import { INSPECTIONS_DETAILS_URL } from 'src/routes/assetRoutes';
import { convertUtcToLocal } from 'src/utils/date';
import { RootState } from 'typesafe-actions';
import { EquipmentGroupEnum } from 'src/constants';
import { InspectionCharacteristics, InspectionDetails } from '../types';

interface Params {
  id: string;
}

const matchSpaceParams = createMatchSelector<RouterRootState, Params>(
  INSPECTIONS_DETAILS_URL
);

export const selectInspectionId = createSelector(
  matchSpaceParams,
  match => match?.params.id || ''
);

export const selectInspectionDetails = (state: RootState) =>
  state.ui.asset.inspections.details;

const getInspectionCharacteristicsRowsConfigs = (
  equipmentGroup: string
): RowConfig<InspectionCharacteristics>[] => {
  return [
    {
      label: 'Номер документа',
      field: 'documentNumber'
    },
    {
      label: 'Дата інспекції',
      field: 'inspectionDate',
      format: ({ inspectionDate }: InspectionCharacteristics) =>
        convertUtcToLocal(inspectionDate, DATE_FORMAT_SLASH)
    },
    { label: 'Інспектор', field: 'inspectorFullName' },
    {
      label: 'Група обладнання',
      field: 'equipmentGroup'
    },
    { label: 'Підрозділ', field: 'counteragent' },
    { label: 'Адреса підрозділу', field: 'address' },
    ...(equipmentGroup !== EquipmentGroupEnum.Mobile
      ? [
          {
            label: 'Працівник підрозділу - відповідальна особа',
            field: 'responsiblePersonFullName'
          }
        ]
      : []),
    ...(equipmentGroup === EquipmentGroupEnum.Mobile
      ? [
          { label: 'Тип ТЗ', field: 'vehicleType' },
          { label: 'Номерний знак ТЗ', field: 'licensePlate' },
          { label: 'Модель ТЗ', field: 'vehicleModel' }
        ]
      : [])
  ].concat([
    {
      label: 'Примітка1',
      field: 'notes',
      format: ({ notes }) => notes || '-'
    },
    {
      label: 'Дата та час збереження документа',
      field: 'modifiedAtUtc',
      format: ({ modifiedAtUtc }) =>
        convertUtcToLocal(
          String(modifiedAtUtc),
          DATETIME_FORMAT_SLASH
        ) as string
    }
  ]) as RowConfig<InspectionCharacteristics>[];
};

export const selectCharacteristicsRows = (details: InspectionDetails | null) =>
  details
    ? getInspectionCharacteristicsRowsConfigs(details?.equipmentGroup).map(
        toDetailsTableRow(details)
      )
    : [];
