import { HourlyDeparture } from '../types';

export const hourlyDepartureReportMock: HourlyDeparture = {
  factCountPerDay: 100,
  plannedCountPerDay: 120,
  ratioPerDay: 0.83,
  counteragents: [
    {
      id: 1,
      name: 'Counteragent 1',
      factCountPerDay: 50,
      plannedCountPerDay: 60,
      ratioPerDay: 0.83,
      routes: [
        {
          id: 1,
          name: 'Route 1',
          factCountPerDay: 25,
          plannedCountPerDay: 30,
          ratioPerDay: 0.83,
          timeSpans: [
            {
              from: '00:00',
              to: '01:00',
              planned: 10,
              fact: 8
            },
            {
              from: '01:00',
              to: '02:00',
              planned: 10,
              fact: 9
            }
          ]
        },
        {
          id: 2,
          name: 'Route 2',
          factCountPerDay: 25,
          plannedCountPerDay: 30,
          ratioPerDay: 0.83,
          timeSpans: [
            {
              from: '00:00',
              to: '01:00',
              planned: 10,
              fact: 7
            },
            {
              from: '01:00',
              to: '02:00',
              planned: 10,
              fact: 8
            }
          ]
        }
      ]
    },
    {
      id: 2,
      name: 'Counteragent 2',
      factCountPerDay: 50,
      plannedCountPerDay: 60,
      ratioPerDay: 0.83,
      routes: [
        {
          id: 3,
          name: 'Route 3',
          factCountPerDay: 25,
          plannedCountPerDay: 30,
          ratioPerDay: 0.83,
          timeSpans: [
            {
              from: '00:00',
              to: '01:00',
              planned: 10,
              fact: 9
            },
            {
              from: '01:00',
              to: '02:00',
              planned: 10,
              fact: 8
            }
          ]
        },
        {
          id: 4,
          name: 'Route 4',
          factCountPerDay: 25,
          plannedCountPerDay: 30,
          ratioPerDay: 0.83,
          timeSpans: [
            {
              from: '00:00',
              to: '01:00',
              planned: 10,
              fact: 7
            },
            {
              from: '01:00',
              to: '02:00',
              planned: 10,
              fact: 8
            }
          ]
        }
      ]
    }
  ]
};
