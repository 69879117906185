import { flattenDeep } from 'lodash';
import { PdfGenerator, RowItems, TypeTable } from 'src/services/pdfGenerator';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL, DETAILED_DATE_TIME_FORMAT } from 'src/config';
import { inboundColumnsPdf, inboundDocumentPdf } from '../const';
import {
  EquipmentDetail,
  InboundDetailsState
} from '../InboundDetailsView/types';
import { SAVE_DOCUMENT_UTC_OFFSET_MINUTES } from '../../const';

export function generatePdfOfInbound(data: InboundDetailsState): PdfGenerator {
  const inboundData = {
    ...data,
    modifiedAtUtc: utcToLocal(
      data.modifiedAtUtc,
      DETAILED_DATE_TIME_FORMAT,
      SAVE_DOCUMENT_UTC_OFFSET_MINUTES
    )
  };

  const equipments = flattenDeep(
    data.equipments.map((item: EquipmentDetail) => {
      const { supplements, ...obj } = item;

      if (supplements?.length) {
        return [
          obj,
          ...supplements.map(suppl => {
            return {
              name: suppl
            };
          })
        ];
      }

      return obj;
    })
  );

  const inboundDocument: RowItems[] = [];

  inboundDocumentPdf.forEach(item => inboundDocument.push(item));

  return new PdfGenerator(
    `Надходження №${data.documentNumber} ${data.counteragentName} ${
      data.acceptingEmployeeName
    } від ${utcToLocal(data.dateInbound, DATE_FORMAT_FULL)}`,
    undefined,
    { orientation: 'l' }
  )
    .setHeader(
      `Акт надходження № ${data.documentNumber || ''} від ${utcToLocal(
        data.dateInbound,
        DATE_FORMAT_FULL
      )}`,
      true
    )
    .setTable(inboundDocument, inboundData, TypeTable.InfoTable)
    .setHeader('Прийняте обладнання', false)
    .setTable(inboundColumnsPdf, equipments, TypeTable.ListTable);
}
