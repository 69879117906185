import * as XLSX from 'sheetjs-style';
import { merge } from 'lodash';
import { getCellAddresses } from './getCellAddresses';
import { centered } from '../ReportsTables/const';
import { StyleBase } from '../ReportsTables/RoutePerforming/types';

export const tableCellStyle = {
  s: {
    ...centered
  }
};

const getStylesForReportWithTable = (
  styleBase: StyleBase = {},
  tableStartAddress: XLSX.CellAddress = { c: 0, r: 0 },
  cellOptions: XLSX.CellObject['s'] = {}
) => (sheetEndAddress: XLSX.CellAddress) => {
  const cellAdresses = getCellAddresses({
    e: sheetEndAddress,
    s: tableStartAddress
  });

  const transformedCells = Object.entries(styleBase).reduce((prev, curr) => {
    const [key, value] = curr;

    const { s, ...rest } = value;

    const newCellStyle = {
      [key]: { s: { ...tableCellStyle.s, ...cellOptions, ...s }, ...rest }
    };

    return { ...prev, ...newCellStyle };
  }, {});

  const cells = cellAdresses
    .map(addr => ({
      [addr]: { ...tableCellStyle, s: { ...tableCellStyle.s, ...cellOptions } }
    }))
    .reduce(merge, {});

  return {
    ...cells,
    ...transformedCells
  };
};
export default getStylesForReportWithTable;
