import moment from 'moment';
import { DETAILED_DATE_FORMAT } from 'src/config';
import { Styles } from 'jspdf-autotable';
import { UnmappedGPSDataReport } from './types';
import { ReportTableConfig } from '../types';
import { ReportFilterData } from '../../types';

export const title = '9. Звіт про отримані неідентифіковані GPS дані';

export const getReportDate = (date: string) =>
  moment(date, DETAILED_DATE_FORMAT).format('DD/MM/YYYY HH:mm:ss');

const reportDetailsStyles: Partial<Styles> = {
  lineColor: 'white',
  halign: 'left',
  fontStyle: 'bold'
};

export const getTableConfig = (
  filterData: ReportFilterData,
  report: UnmappedGPSDataReport
): ReportTableConfig => [
  [
    {
      colSpan: 8,
      value: title,
      styles: {
        lineColor: 'white',
        fontStyle: 'bold',
        halign: 'center'
      }
    }
  ],
  [
    {
      value: 'Дата з',
      colSpan: 1,
      styles: reportDetailsStyles
    },
    {
      colSpan: 7,
      value: filterData.dateFrom,
      styles: reportDetailsStyles
    }
  ],
  [
    {
      value: 'Дата до',
      colSpan: 1,
      styles: reportDetailsStyles
    },
    {
      colSpan: 7,
      value: filterData.dateTo,
      styles: reportDetailsStyles
    }
  ],
  [],
  [
    {
      value: 'Дата та час',
      colSpan: 1,
      rowSpan: 2,
      styles: { fontStyle: 'bold' }
    },
    {
      value: 'Штрихкод консолі',
      colSpan: 1,
      rowSpan: 2,
      styles: { fontStyle: 'bold' }
    },
    {
      value: 'AVL',
      colSpan: 3,
      styles: { fontStyle: 'bold' }
    },
    {
      value: 'AFC',
      colSpan: 2,
      styles: { fontStyle: 'bold' }
    },
    {
      value: 'Помилка',
      colSpan: 1,
      rowSpan: 2,
      styles: { fontStyle: 'bold' }
    }
  ],
  [
    { value: 'Номер ТЗ', styles: { fontStyle: 'bold' } },
    { value: 'Водій (ІД)', styles: { fontStyle: 'bold' } },
    { value: 'Номер маршруту', styles: { fontStyle: 'bold' } },
    { value: 'Номер ТЗ', styles: { fontStyle: 'bold' } },
    { value: 'Номер маршруту', styles: { fontStyle: 'bold' } }
  ],
  [
    { colSpan: 1, value: 'timestamp', styles: { fontStyle: 'bold' } },
    { colSpan: 1, value: 'consoleBarcode', styles: { fontStyle: 'bold' } },
    {
      colSpan: 1,
      value: 'avlVehicleRegistrationNumber',
      styles: { fontStyle: 'bold' }
    },
    { colSpan: 1, value: 'driverPersonNumber', styles: { fontStyle: 'bold' } },
    {
      colSpan: 1,
      value: 'lineRegistrationNumber',
      styles: { fontStyle: 'bold' }
    },
    {
      colSpan: 1,
      value: 'afcVehicleRegistrationNumber',
      styles: { fontStyle: 'bold' }
    },
    {
      colSpan: 1,
      value: 'lineRegistrationNumber',
      styles: { fontStyle: 'bold' }
    },
    { colSpan: 1, value: 'error', styles: { fontStyle: 'bold' } }
  ],
  ...(report.map(reportEl => [
    { colSpan: 1, value: getReportDate(reportEl.timestamp) },
    { colSpan: 1, value: reportEl.consoleBarcode },
    { colSpan: 1, value: reportEl.avl.vehicleRegistrationNumber },
    { colSpan: 1, value: reportEl.avl.driverPersonNumber },
    { colSpan: 1, value: reportEl.avl.lineRegistrationNumber },
    { colSpan: 1, value: reportEl.afc.vehicleRegistrationNumber },
    { colSpan: 1, value: reportEl.afc.lineRegistrationNumber },
    { colSpan: 1, value: reportEl.error }
  ]) ?? [])
];
