import * as XLSX from 'sheetjs-style';

export const SUMMARY_ROW_FILL_COLOR = '79aee7';

type ColumnName = string;

export type SummaryRow = {
  row: number;
  styles?: Record<ColumnName, Partial<XLSX.CellObject>>;
};

export const getSummaryRowStyles = (
  summaryRows: SummaryRow[],
  columnsNumber: number
) =>
  summaryRows.reduce((accumulator, { row, styles = {} }) => {
    Array(columnsNumber)
      .fill('')
      .forEach((_, index) => {
        const columnName = XLSX.utils.encode_col(index);

        accumulator[`${columnName}${row}`] = {
          s: {
            font: {
              bold: true
            },
            fill: {
              fgColor: { rgb: SUMMARY_ROW_FILL_COLOR }
            },
            ...(styles[columnName]?.s ?? {})
          }
        };
      });

    return accumulator;
  }, {} as Record<string, Partial<XLSX.CellObject>>);
