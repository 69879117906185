import { FilterType } from '../../components/Tables/TableWithPagination/types';
import {
  COUNTERAGENTS,
  COUNTERAGENTS_AUTOCOMPLETE,
  WAREHOUSE_COUNTERAGENTS_AUTOCOMPLETE
} from '../../config/avlUrls';
import {
  TRANSFERING_COUNTERAGENTS_HOLDER_NAME,
  TRANSFERING_COUNTERAGENTS_HOLDER_PATH
} from './transfer/TransferStep/const';

export const COUNTERAGENTS_HOLDER_NAME = 'COUNTERAGENTS_HOLDER_AUTOCOMPLETE';
export const COUNTERAGENTS_HOLDER_PATH =
  'asset.installation.counteragentsHolderAutocomplete';

export const COUNTERAGENTS_TRANSFERING_NAME =
  'TRANSFERING_COUNTERAGENTS_TRANSFERING_AUTOCOMPLETE';
export const COUNTERAGENTS_TRANSFERING_PATH =
  'asset.transfer.counteragentsTransferAutocomplete';

export const COUNTERAGENTS_FILTER_NAME = 'COUNTREAGENT_FILTER';
export const COUNTERAGENTS_FILTER_REDUCER_PATH = 'asset.counteragents';

export const WAREHOUSE_TYPE_ID = 2;

type FilterConfig = {
  label?: string;
  width?: number | string;
  name?: string;
  reducerName?: string;
  reducerPath?: string;
  counteragentTypeId?: number;
  optionsApiUrl?: string;
};

export const createCounteragentFilter = ({
  counteragentTypeId,
  reducerPath,
  reducerName,
  optionsApiUrl,
  ...config
}: FilterConfig = {}) => ({
  name: 'counteragentId',
  label: 'Підрозділ',
  type: FilterType.Autocomplete,
  autocompleteConfig: {
    reducerName: reducerName ?? COUNTERAGENTS_FILTER_NAME,
    reducerPath: reducerPath ?? COUNTERAGENTS_FILTER_REDUCER_PATH,
    optionsApiUrl: optionsApiUrl ?? COUNTERAGENTS_AUTOCOMPLETE,
    value: 'id'
  },
  optionsURL: counteragentTypeId
    ? COUNTERAGENTS
    : WAREHOUSE_COUNTERAGENTS_AUTOCOMPLETE,
  ...config
});

export const createCounteragentTransferFilter = (config: FilterConfig = {}) =>
  createCounteragentFilter({
    ...config,
    name: 'counteragentTransferId',
    label: 'Підрозділ до',
    optionsApiUrl: WAREHOUSE_COUNTERAGENTS_AUTOCOMPLETE,
    reducerName: COUNTERAGENTS_TRANSFERING_NAME,
    reducerPath: COUNTERAGENTS_TRANSFERING_PATH
  });

export const createCounteragentHolderFilter = (config: FilterConfig = {}) =>
  createCounteragentFilter({
    name: 'counteragentHolderId',
    label: 'Підрозділ від',
    optionsApiUrl: WAREHOUSE_COUNTERAGENTS_AUTOCOMPLETE,
    reducerName: TRANSFERING_COUNTERAGENTS_HOLDER_NAME,
    reducerPath: TRANSFERING_COUNTERAGENTS_HOLDER_PATH,
    ...config
  });

export const SAVE_DOCUMENT_UTC_OFFSET_MINUTES = 120;
