import * as XLSX from 'sheetjs-style';
import { boldText, centered } from '../const';
import getStylesForReportWithTable from '../../utils/getStylesForReportWithTable';
import { StyleBase } from './types';

const styleBase: StyleBase = {
  A1: {
    s: {
      ...boldText,
      ...centered
    }
  },
  A2: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  A3: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  A5: {
    s: {
      ...boldText
    }
  },
  B2: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  B3: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  B5: {
    s: {
      ...boldText
    }
  },
  C5: {
    s: {
      ...boldText
    }
  },
  D5: {
    s: {
      ...boldText
    }
  },
  E5: {
    s: {
      ...boldText
    }
  },
  F5: {
    s: {
      ...boldText
    }
  },
  G5: {
    s: {
      ...boldText
    }
  },
  H5: {
    s: {
      ...boldText
    }
  },
  A6: {
    s: {
      ...boldText
    }
  },
  B6: {
    s: {
      ...boldText
    }
  },
  C6: {
    s: {
      ...boldText
    }
  },
  D6: {
    s: {
      ...boldText
    }
  },
  E6: {
    s: {
      ...boldText
    }
  },
  F6: {
    s: {
      ...boldText
    }
  },
  G6: {
    s: {
      ...boldText
    }
  },
  H6: {
    s: {
      ...boldText
    }
  },
  I6: {
    s: {
      ...boldText
    }
  },
  A7: {
    s: {
      ...boldText
    }
  },
  B7: {
    s: {
      ...boldText
    }
  },
  C7: {
    s: {
      ...boldText
    }
  },
  D7: {
    s: {
      ...boldText
    }
  },
  E7: {
    s: {
      ...boldText
    }
  },
  F7: {
    s: {
      ...boldText
    }
  },
  G7: {
    s: {
      ...boldText
    }
  },
  H7: {
    s: {
      ...boldText
    }
  },
  I7: {
    s: {
      ...boldText
    }
  }
};

const TABLE_START_ADDRESS: XLSX.CellAddress = { r: 5, c: 0 };

export const getStyles = () =>
  getStylesForReportWithTable(styleBase, TABLE_START_ADDRESS);
