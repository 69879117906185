import { TRANSFERS } from 'src/config/assetUrls';

export const EDIT_TRANSFER_FORM_NAME = 'EDIT_TRANSFER_FORM';
export const EDIT_TRANSFER_FORM_PATH = 'asset.transfer.edit.data';
export const EDIT_TRANSFER_FORM_CONFIG = {
  reducerName: EDIT_TRANSFER_FORM_NAME,
  reducerPath: EDIT_TRANSFER_FORM_PATH
};

export const EDIT_TRANSFERING_EQUIPMENT_NAME =
  'EDIT_TRANSFERING_EQUIPMENT_NAME';
export const EDIT_TRANSFERING_EQUIPMENT_PATH = 'asset.transfer.edit.table';

export const EDIT_TRANSFERING_TABLE_CONFIG = (id: number) => ({
  reducerName: EDIT_TRANSFERING_EQUIPMENT_NAME,
  reducerPath: EDIT_TRANSFERING_EQUIPMENT_PATH,
  apiUrl: `${TRANSFERS}/${id}/equipment/stationary`,
  snackbarMessages: {
    deleteRowSuccess: 'Успішно відмінено переміщення'
  },
  enableSetRowsIsLocalProperty: true
});

export const content = 'Ви впевнені, що хочете завершити переміщення?';

export const TRANSFER_ERROR_MESSAGES: Record<string, string> = {
  forbidden:
    "Неможливо зафіксувати документ. Стан обладнання було змінено на 'Виведено'. Дане обладнання необхідно видалити з цього документу",
  notFound:
    'Неможливо зберегти документ. Детальна частина документа не актуальна'
};
