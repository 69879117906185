import moment from 'moment';
import { DETAILED_DATE_FORMAT, TIME_FORMAT } from 'src/config';

export const formatTime = (date?: Date | string) =>
  date ? moment(date, TIME_FORMAT).format(TIME_FORMAT) : '';

export const resetTimeToStartOfDay = (date?: string | null) => {
  if (!date) {
    return null;
  }

  return moment(date)
    .startOf('day')
    .format(DETAILED_DATE_FORMAT);
};

export const resetTimeToEndOfDay = (date?: string | null) => {
  if (!date) {
    return null;
  }

  return moment(date)
    .endOf('day')
    .format(DETAILED_DATE_FORMAT);
};

export const adjustTimeForDateTo = (dateTo?: string | null) => {
  if (!dateTo) {
    return null;
  }

  return resetTimeToEndOfDay(dateTo);
};
