import { sortBy } from 'lodash';
import { extractNumericPart } from 'src/utils/extractNumericPart';
import { ReportFilterData } from '../../types';
import { ReportTableConfig, TableRow } from '../types';
import { Counteragent, Route, WorkOfCarrierReport } from './types';
import { SUMMARY_ROW_FILL_COLOR } from '../../../../../../../utils/xlsx/getSummaryRowStyles';

const transformRoutes = (routes: Route[]) =>
  routes.map(route => [
    {
      value: route.routeName
    },
    {
      value: route.release.ratio
    },
    {
      value: route.presence.ratio
    },
    {
      value: route.summary.completeness
    },
    {
      value: route.summary.graphicity
    }
  ]);

export const title = '4. Звіт про роботу перевізника';

export const getCounteragentSection = (
  counteragent: Counteragent
): TableRow[] => {
  const routesSorted = sortBy(counteragent.routes, r =>
    extractNumericPart(r.routeName)
  );

  return [
    [
      {
        colSpan: 5,
        value: counteragent.counteragentName,
        styles: { fillColor: SUMMARY_ROW_FILL_COLOR, fontStyle: 'bold' }
      }
    ],
    ...transformRoutes(routesSorted),
    [
      {
        value: `Всього по перевізнику ${counteragent.counteragentName}`
      },
      { value: counteragent.release.ratio },
      { value: counteragent.presence.ratio },
      { value: counteragent.summary.completeness },
      { value: counteragent.summary.graphicity }
    ].map(cell => ({
      ...cell,
      styles: {
        fillColor: SUMMARY_ROW_FILL_COLOR,
        fontStyle: 'bold'
      }
    }))
  ];
};

export const getTableConfig = (
  filterData: ReportFilterData,
  report: WorkOfCarrierReport
): ReportTableConfig =>
  [
    [{ value: title, colSpan: 5, styles: { fontStyle: 'bold' } }],
    [
      { value: 'Дата', styles: { fontStyle: 'bold' } },
      { value: filterData.date, colSpan: 4, styles: { halign: 'left' } }
    ] as TableRow,
    [],
    [],
    [
      { value: 'Маршрут' },
      { value: 'Випуск на маршрут, %' },
      { value: 'Присутність на маршруті, %' },
      { value: 'Виконання їздок, %' },
      { value: 'Графічність, %  ' }
    ].map(cell => ({
      ...cell,
      styles: {
        fontStyle: 'bold'
      }
    })) as TableRow,
    ...report.counteragents.map(getCounteragentSection).flat()
  ].map(config =>
    config.map(cell => ({
      ...cell,
      styles: {
        fontSize: 10,
        valign: 'middle',
        halign: 'center',
        ...cell.styles
      }
    }))
  );
