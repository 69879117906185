import { WorkOfCarrierReport } from '../types';

export const workOfCarrierReportMock: WorkOfCarrierReport = {
  type: 1,
  name: 'Carrier Report',
  onDate: '2023-10-01',
  counteragents: [
    {
      counteragentId: 1,
      counteragentName: 'Counteragent 1',
      release: {
        fact: 50,
        planned: 60,
        ratio: 0.83
      },
      presence: {
        fact: 45,
        planned: 55,
        ratio: 0.82
      },
      summary: {
        attendance: 90,
        graphicity: 85,
        completeness: 88,
        distanceOnRoute: {
          fact: 100,
          planned: 120
        },
        numberOfRides: {
          fact: 80,
          planned: 100
        },
        visitedBusStops: {
          fact: 70,
          planned: 80,
          onSchedule: 60,
          inSpecialMode: 10,
          unavailableStops: 5
        }
      },
      routes: [
        {
          routeName: 'Route 1',
          presence: {
            fact: 20,
            planned: 25,
            ratio: 0.8
          },
          release: {
            fact: 22,
            planned: 28,
            ratio: 0.79
          },
          summary: {
            attendance: 85,
            graphicity: 80,
            completeness: 82,
            distanceOnRoute: {
              fact: 50,
              planned: 60
            },
            numberOfRides: {
              fact: 40,
              planned: 50
            },
            visitedBusStops: {
              fact: 35,
              planned: 40,
              onSchedule: 30,
              inSpecialMode: 5,
              unavailableStops: 2
            }
          }
        },
        {
          routeName: 'Route 2',
          presence: {
            fact: 25,
            planned: 30,
            ratio: 0.83
          },
          release: {
            fact: 23,
            planned: 29,
            ratio: 0.79
          },
          summary: {
            attendance: 88,
            graphicity: 82,
            completeness: 85,
            distanceOnRoute: {
              fact: 55,
              planned: 65
            },
            numberOfRides: {
              fact: 45,
              planned: 55
            },
            visitedBusStops: {
              fact: 38,
              planned: 45,
              onSchedule: 33,
              inSpecialMode: 7,
              unavailableStops: 3
            }
          }
        }
      ]
    }
  ]
};
