import * as XLSX from 'sheetjs-style';
import { last } from 'lodash';
import getStylesForReportWithTable from '../../utils/getStylesForReportWithTable';
import { boldText, firstRowStyles, leftAligned } from '../const';
import { BusWorkOnRouteReport } from './types';
import {
  getSummaryRowStyles,
  SummaryRow
} from '../../../../../../../utils/xlsx/getSummaryRowStyles';
import { BUS_WORK_ON_ROUTE_HEADERS_NUMBER } from './getTableConfig';
import { StyleBase } from '../UnmappedGPSData/types';

const styleBase = {
  A1: {
    s: firstRowStyles
  },
  A2: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  C2: {
    s: {
      ...boldText,
      alignment: {
        horizontal: 'left'
      }
    }
  },
  A3: {
    s: {
      ...boldText
    }
  },
  A4: {
    s: {
      ...boldText
    }
  },
  B4: {
    s: {
      ...boldText
    }
  },
  C4: {
    s: {
      ...boldText
    }
  },
  D4: {
    s: {
      ...boldText
    }
  },
  E4: {
    s: {
      ...boldText
    }
  },
  F4: {
    s: {
      ...boldText
    }
  },
  G4: {
    s: {
      ...boldText
    }
  },
  H4: {
    s: {
      ...boldText
    }
  },
  J4: {
    s: {
      ...boldText
    }
  },
  L4: {
    s: {
      ...boldText
    }
  },
  N4: {
    s: {
      ...boldText
    }
  },
  R4: {
    s: {
      ...boldText
    }
  },
  T4: {
    s: {
      ...boldText
    }
  },
  U4: {
    s: {
      ...boldText
    }
  },
  V4: {
    s: {
      ...boldText
    }
  },
  F5: {
    s: {
      ...boldText
    }
  },
  G5: {
    s: {
      ...boldText
    }
  },
  H5: {
    s: {
      ...boldText
    }
  },
  I5: {
    s: {
      ...boldText
    }
  },
  J5: {
    s: {
      ...boldText
    }
  },
  K5: {
    s: {
      ...boldText
    }
  },
  L5: {
    s: {
      ...boldText
    }
  },
  M5: {
    s: {
      ...boldText
    }
  },
  N5: {
    s: {
      ...boldText
    }
  },
  O5: {
    s: {
      ...boldText
    }
  },
  P5: {
    s: {
      ...boldText
    }
  },
  Q5: {
    s: {
      ...boldText
    }
  },
  R5: {
    s: {
      ...boldText
    }
  },
  S5: {
    s: {
      ...boldText
    }
  }
};

const TABLE_START_ADDRESS: XLSX.CellAddress = { r: 3, c: 0 };

export const getStyles = (report: BusWorkOnRouteReport) => {
  // table body starts from 6th row
  const summaryRows: SummaryRow[] = [{ row: 6 }, { row: 7 }];

  report.counteragents.forEach(({ routes }, counteragentIndex) => {
    const isFirstCounteragent = counteragentIndex === 0;

    if (!isFirstCounteragent) {
      // new counteragent
      summaryRows.push({ row: last(summaryRows)!.row + 1 });
      // new route
      summaryRows.push({ row: last(summaryRows)!.row + 1 });
    }

    routes.forEach(({ departures }, index) => {
      const lastRow = last(summaryRows)!;
      const offset = departures.reduce(
        (acc, { workShifts }) => acc + workShifts.length,
        0
      );
      const isLastRoute = index === routes.length - 1;

      summaryRows.push({
        row: lastRow.row + offset + 1,
        styles: {
          A: {
            s: {
              ...leftAligned
            }
          }
        }
      });

      summaryRows.push({
        row: lastRow.row + offset + 2,
        // last row is a summary by counteragent
        styles: isLastRoute
          ? {
              A: {
                s: {
                  ...leftAligned
                }
              }
            }
          : undefined
      });
    });
  });

  return getStylesForReportWithTable(
    {
      ...styleBase,
      ...getSummaryRowStyles(summaryRows, BUS_WORK_ON_ROUTE_HEADERS_NUMBER)
    } as StyleBase,
    TABLE_START_ADDRESS
  );
};
