import { SheetDimensions } from '../../../../../../../utils/downloadXlsxFileFromTable';

const dimensions: SheetDimensions = {
  cell: {
    heights: {
      F4: 30
    }
  },
  column: {
    widths: {
      D4: 80,
      T4: 90,
      V4: 90,
      U4: 90,
      Q5: 80
    }
  }
};

export default dimensions;
