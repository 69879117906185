import { Grid } from '@material-ui/core';
import { memoize } from 'lodash';
import { FormAutocomplete, FormDatePicker } from 'src/components/Form';
import Select from 'src/components/Select';
import { SelectConfig } from 'src/components/Select/types';
import { getEmployeesConfig } from 'src/views/avl/vehicles/const';
import { COUNTERAGENTS, ROUTE_PASSPORT_AUTOCOMPLETE } from 'src/config/avlUrls';
import { AutocompleteConfig } from 'src/components/Form/FormAutocomplete/types';
import { getRouteName, RoutesOption } from 'src/utils/routes';
import { DownloadReportsFormValues } from '../types';
import { ReportFilterType, ReportType } from '../../types';
import { REPORTS_WITH_PERIOD } from '../const';

export const AVL_REPORTS_COUNTERAGENTS_NAME =
  'AVL_REPORTS_COUNTERAGENTS_AUTOCOMPLETE';
export const COUNTERAGENTS_PATH = 'avl.reports.counteragents';
const COUNTERAGENT_CONFIG: SelectConfig = {
  optionsApiUrl: `${COUNTERAGENTS}?withAprovedRoutePassport=true`,
  reducerName: AVL_REPORTS_COUNTERAGENTS_NAME,
  reducerPath: COUNTERAGENTS_PATH
};

export const AVL_REPORTS_ROUTES_NAME = 'AVL_REPORTS_ROUTES_AUTOCOMPLETE';
export const ROUTES_PATH = 'avl.reports.routes';
const ROUTES_CONFIG = memoize(
  (id: number | null): AutocompleteConfig => ({
    optionsApiUrl: id
      ? `${ROUTE_PASSPORT_AUTOCOMPLETE}?counteragentId=${id}`
      : '',
    reducerName: AVL_REPORTS_ROUTES_NAME,
    reducerPath: ROUTES_PATH
  })
);

export const AVL_REPORTS_DRIVERS_NAME = 'AVL_REPORTS_DRIVERS_AUTOCOMPLETE';

interface FilterProps {
  values: DownloadReportsFormValues;
}

export const componentByReportFilterType: Record<
  ReportFilterType,
  (props: FilterProps) => JSX.Element
> = {
  counteragent: () => (
    <Grid item xs={3}>
      <Select
        defaultItem={{
          id: 0,
          name: 'Всі'
        }}
        name="filters.counteragent"
        label="Підрозділ"
        config={COUNTERAGENT_CONFIG}
      />
    </Grid>
  ),
  date: ({ values: { filters, reportType } }) => {
    let disabled = filters.counteragent === null;

    if (reportType && +reportType === ReportType.graphicityPerformance) {
      disabled = filters.counteragent === null || filters.counteragent === 0;
    }

    return (
      <Grid item xs={3}>
        <FormDatePicker
          name="filters.date"
          label="Дата"
          nativeProps={{
            disabled
          }}
          disableFuture
          disableToday
        />
      </Grid>
    );
  },
  route: ({ values: { filters } }) => {
    const counteragentId = filters.counteragent || 0;

    return (
      <Grid item xs={3}>
        <FormAutocomplete<RoutesOption>
          name="filters.route"
          label="Маршрут"
          config={ROUTES_CONFIG(+counteragentId)}
          renderOption={getRouteName}
          nativeProps={{
            getOptionLabel: getRouteName,
            disabled: !counteragentId
          }}
        />
      </Grid>
    );
  },
  driver: ({ values: { filters } }) => {
    const counteragentId = filters.counteragent || 0;

    return (
      <Grid item xs={3}>
        <FormAutocomplete
          name="filters.driver"
          label="Водій"
          config={getEmployeesConfig(counteragentId || 0)}
          nativeProps={{
            disabled: !counteragentId
          }}
        />
      </Grid>
    );
  },
  dateFrom: ({ values: { filters, reportType } }) => {
    const numericReportType = reportType ? +reportType : null;

    const disabled =
      !!numericReportType &&
      REPORTS_WITH_PERIOD.includes(numericReportType) &&
      filters.counteragent === null;

    return (
      <Grid item xs={3}>
        <FormDatePicker
          name="filters.dateFrom"
          label="Дата з"
          disableFuture
          disableToday
          nativeProps={{
            disabled
          }}
        />
      </Grid>
    );
  },
  dateTo: ({ values: { filters, reportType } }) => {
    const numericReportType = reportType ? +reportType : null;

    let disabled =
      numericReportType === ReportType.workOfDriver && !filters.driver?.id;

    if (numericReportType === ReportType.busWorkOnRouteReport) {
      disabled = !filters.route || !filters.dateFrom;
    }

    return (
      <Grid item xs={3}>
        <FormDatePicker
          nativeProps={{ disabled, minDate: filters.dateFrom }}
          name="filters.dateTo"
          label="Дата до"
          disableToday
          disableFuture
        />
      </Grid>
    );
  }
};

export const filterTypesByReportType: Record<ReportType, ReportFilterType[]> = {
  [ReportType.busWorkOnRouteReport]: [
    'counteragent',
    'route',
    'dateFrom',
    'dateTo'
  ],
  [ReportType.hourlyDeparture]: ['counteragent', 'route', 'date'],
  [ReportType.workOfDriver]: ['counteragent', 'driver', 'dateFrom', 'dateTo'],
  [ReportType.workOfCarrier]: ['counteragent', 'date'],
  [ReportType.graphicityPerformance]: ['counteragent', 'route', 'date'],
  [ReportType.morningAndEveningRide]: ['counteragent', 'route', 'date'],
  [ReportType.routePerforming]: ['counteragent', 'route', 'dateFrom', 'dateTo'],
  [ReportType.presenceOnRouteNetwork]: ['counteragent', 'route', 'date'],
  [ReportType.unmappedGPSData]: ['dateFrom', 'dateTo']
};
