import { PdfGenerator, TypeTable } from 'src/services/pdfGenerator';
import { utcToLocal } from 'src/utils/date';
import { DATE_FORMAT_FULL, DETAILED_DATE_TIME_FORMAT } from 'src/config';
import {
  uninstallationDocumentPdf,
  uninstallationMobileColumnsPdf,
  uninstallationStationaryColumnsPdf
} from '../const';
import { UninstallationPayload } from '../UninstallationDetailsView/store/types';
import { SAVE_DOCUMENT_UTC_OFFSET_MINUTES } from '../../const';

export function generatePdfOfUninstallation(
  data: UninstallationPayload
): PdfGenerator {
  const uninstallationData = {
    ...data,
    modifiedAtUtc: utcToLocal(
      data.modifiedAtUtc,
      DETAILED_DATE_TIME_FORMAT,
      SAVE_DOCUMENT_UTC_OFFSET_MINUTES
    )
  };

  const {
    documentNumber,
    mobileEquipment,
    stationaryEquipment,
    inspectorEquipments,
    uninstallationDate,
    uninstallingEmployeeName
  } = uninstallationData;

  const dateTitle = utcToLocal(uninstallationDate as Date, DATE_FORMAT_FULL);
  const dateSubject = utcToLocal(uninstallationDate as Date, DATE_FORMAT_FULL);

  const title = `Демонтаж №${documentNumber} ${uninstallingEmployeeName} ${dateTitle}`;
  const subject = `Акт демонтажу № ${documentNumber} від ${dateSubject}`;

  return new PdfGenerator(title, 13, { orientation: 'landscape' })
    .setHeader(subject, true)
    .setTable(
      uninstallationDocumentPdf,
      uninstallationData,
      TypeTable.InfoTable
    )
    .setHeader('Демонтоване мобільне обладнання', false)
    .setTable(
      uninstallationMobileColumnsPdf,
      mobileEquipment,
      TypeTable.ListTable
    )
    .setHeader('Демонтоване стаціонарне обладнання', false)
    .setTable(
      uninstallationStationaryColumnsPdf,
      stationaryEquipment,
      TypeTable.ListTable
    )
    .setHeader('Демонтоване обладнання для контролерів', false)
    .setTable(
      uninstallationStationaryColumnsPdf,
      inspectorEquipments,
      TypeTable.ListTable
    );
}
