import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { get, isBoolean } from 'lodash';
import { RootState } from 'typesafe-actions';
import { STATUS_CONFIG } from 'src/constants';
import { Row } from 'src/components/Tables/types';

const matchSpaceParams = createMatchSelector('/avl/employees/:id/details');

export const selectEmployeeId = createSelector(
  matchSpaceParams,
  (match: any) => match?.params.id || ''
);

export const selectIsLoadingEmployee = (state: RootState) => {
  const loading = get(state, `loading.@EMPLOYEES_DETAILS/GET_EMPLOYEE`);

  return isBoolean(loading) ? loading : true;
};

export const selectShortName = (state: RootState): string =>
  get(state, `ui.avl.employees.details.shortName`);

export const selectEmployeeRows = (state: RootState) => {
  const {
    name,
    department,
    profession,
    position,
    status,
    code,
    description,
    contacts
  } = get(state, `ui.avl.employees.details`);

  const contactList = contacts.map(
    ({ phoneNumber, email }: Row, index: number) => ({
      label: `Контакт ${contacts.length === 1 ? '' : index + 1}`,
      value: [phoneNumber, email].join('\n')
    })
  );

  return [
    { label: 'ПІБ', value: name },
    { label: 'Підрозділ', value: department },
    { label: 'Професія', value: profession },
    { label: 'Посада', value: position },
    { label: 'Статус', value: STATUS_CONFIG[status?.toLowerCase()]?.label },
    { label: 'Код', value: code },
    { label: 'Примітка', value: description }
  ].concat(contactList);
};

export const selectDeleteDisable = (state: RootState) => {
  const { status } = get(state, 'ui.avl.employees.details');

  return status === 'Enabled';
};
