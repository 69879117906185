import { SheetDimensions } from '../../../../../../../utils/downloadXlsxFileFromTable';

const dimensions: SheetDimensions = {
  column: {
    widths: {
      A4: 150
    }
  }
};

export default dimensions;
