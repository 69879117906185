import { TIME_FORMAT } from 'src/config';
import moment from 'moment';
import { TableRow } from '../types';
import { MorningEveningRideData } from './types';

export const formatField = (value: string | null) =>
  value ? moment(value).format(TIME_FORMAT) : '-';

export const getMorningEveningRidesColumns = (
  data: MorningEveningRideData[]
): TableRow[] =>
  data.map(dataItem => [
    {
      value: dataItem.licensePlate
    },
    {
      value: `${dataItem.busStopName} (${dataItem.busStopCode})`
    },
    {
      value: formatField(dataItem.departure.planned)
    },
    {
      value: formatField(dataItem.departure.fact)
    },
    {
      value: formatField(dataItem.arrival.planned)
    },
    {
      value: formatField(dataItem.arrival.fact)
    }
  ]);
