import { flatten } from 'lodash';
import { emptyDataPlaceholder } from 'src/utils/text';
import moment from 'moment';
import { ReportFilterData } from '../../types';
import { formatTime } from '../../utils';
import { ReportTableConfig } from '../types';
import {
  BusWorkOnRouteReport,
  CounteragentsEntity,
  DeparturesEntity,
  RoutesEntity,
  Summary
} from './types';
import { DATE_FORMAT_FULL } from '../../../../../../../config';
import { SUMMARY_ROW_FILL_COLOR } from '../../../../../../../utils/xlsx/getSummaryRowStyles';

const headers = [
  {
    label: 'Дата'
  },
  {
    label: 'Випуск'
  },
  {
    label: 'Зміна'
  },
  {
    label: 'Транспортний засіб',
    cellWidth: 15
  },
  {
    label: 'Водії'
  },
  {
    label: 'Час початку роботи на маршруті, год',
    children: ['план', 'факт']
  },
  {
    label: 'Час закінчення роботи на маршруті, год',
    children: ['план', 'факт']
  },
  {
    label: 'Тривалість роботи на маршруті, год',
    children: ['план', 'факт']
  },
  {
    label: 'Кількість кіл',
    children: ['план', 'факт']
  },
  {
    label: 'Кількість зупинок',
    children: ['план', 'факт', 'по графіку', 'в спецрежимі']
  },
  {
    label: 'Пробіг, км',
    children: ['план', 'факт']
  },
  {
    label: 'Повнота виконнання рейсів, %',
    colSpan: 2,
    cellWidth: 15
  },
  {
    label: 'Графічність, %',
    colSpan: 2,
    cellWidth: 15
  },
  {
    label: 'Відвідуваність зупинок, %',
    colSpan: 2,
    cellWidth: 15
  }
];

export const title = '1. Звіт про роботу транспортних засобів на маршруті';

export const BUS_WORK_ON_ROUTE_HEADERS_NUMBER = headers.reduce(
  (acc, header) => acc + (header.children?.length ?? 1),
  0
);

const headerStyles = {
  valign: 'middle',
  halign: 'center',
  fontStyle: 'bold'
} as const;

const subheaderStyles = {
  ...headerStyles,
  fillColor: SUMMARY_ROW_FILL_COLOR
} as const;

const summaryRowStyles = {
  fontStyle: 'bold',
  fillColor: SUMMARY_ROW_FILL_COLOR
};

const generateSummaryRow = (summary: Summary, label: string) =>
  [
    {
      value: label,
      colSpan: 11,
      styles: {
        fillColor: SUMMARY_ROW_FILL_COLOR
      }
    },
    { value: summary.numberOfRides.planned },
    { value: summary.numberOfRides.fact },
    { value: summary.visitedBusStops.planned },
    { value: summary.visitedBusStops.fact },
    { value: summary.visitedBusStops.onSchedule },
    { value: summary.visitedBusStops.inSpecialMode },
    { value: summary.distanceOnRoute.planned },
    { value: summary.distanceOnRoute.fact },
    { value: summary.completeness },
    { value: summary.graphicity },
    { value: summary.attendance }
  ].map(val => ({
    ...val,
    styles: summaryRowStyles
  }));

const generateData = ({
  startOfWorkTime,
  endOfWorkTime,
  durationOfWorkTime,
  summary
}: DeparturesEntity): (number | string)[] => {
  // fix for those two fields possible being null
  const factStartOfWorkTime = startOfWorkTime.fact?.toString() || '';
  const factEndOfWorkTime = endOfWorkTime.fact?.toString() || '';
  const factDuration = durationOfWorkTime.fact?.toString();

  return [
    startOfWorkTime.planned.toString(),
    factStartOfWorkTime,
    endOfWorkTime.planned.toString(),
    factEndOfWorkTime,
    formatTime(durationOfWorkTime.planned.toString()),
    factDuration !== '00:00:00' ? formatTime(factDuration) : '',
    summary.numberOfRides.planned,
    summary.numberOfRides.fact,
    summary.visitedBusStops.planned,
    summary.visitedBusStops.fact,
    summary.visitedBusStops.onSchedule,
    summary.visitedBusStops.inSpecialMode,
    summary.distanceOnRoute.planned,
    summary.distanceOnRoute.fact,
    summary.completeness,
    summary.graphicity,
    summary.attendance
  ];
};

const generateDepartureRows = (departures: DeparturesEntity[]) =>
  departures
    .map(departure =>
      departure.workShifts.map((workShift, index) => [
        ...(index === 0
          ? [
              {
                value: moment(departure.date).format(DATE_FORMAT_FULL),
                rowSpan: departure.workShifts.length
              },
              {
                value: departure.departureSequenceNumber,
                rowSpan: departure.workShifts.length
              }
            ]
          : []),
        { value: workShift.name },
        { value: workShift.licensePlate },
        { value: workShift.driver },
        ...(index === 0
          ? generateData(departure).map(value => ({
              rowSpan: departure.workShifts.length,
              value: emptyDataPlaceholder(value)
            }))
          : [])
      ])
    )
    .flat();

const generateRouteRows = (routes: RoutesEntity[]) =>
  routes
    .map(({ route, departures, summary, routeNumber }) => [
      [
        {
          value: `Маршрут: ${routeNumber}, ${route}`,
          colSpan: BUS_WORK_ON_ROUTE_HEADERS_NUMBER,
          styles: subheaderStyles
        }
      ],
      ...generateDepartureRows(departures),
      generateSummaryRow(summary, `Всього по маршруту ${routeNumber}, ${route}`)
    ])
    .flat();

const generateCounteragentRows = (counteragents: CounteragentsEntity[]) =>
  counteragents
    .map(({ routes, counteragent, summary }) => [
      [
        {
          value: counteragent,
          colSpan: BUS_WORK_ON_ROUTE_HEADERS_NUMBER,
          styles: subheaderStyles
        }
      ],
      ...generateRouteRows(routes),
      generateSummaryRow(summary, `Всього по перевізнику ${counteragent}`)
    ])
    .flat();

export const getTableConfig = (
  filterData: ReportFilterData,
  report: BusWorkOnRouteReport
): ReportTableConfig => [
  [
    {
      value: title,
      colSpan: 22,
      styles: { ...headerStyles, lineColor: 'white' }
    }
  ],
  [
    {
      value: 'За період:',
      colSpan: 2,
      styles: {
        ...headerStyles,
        lineColor: 'white',
        halign: 'left'
      }
    },
    {
      value: `${filterData.dateFrom} - ${filterData.dateTo}`,
      colSpan: 20,
      styles: {
        lineColor: 'white',
        halign: 'left',
        fontStyle: 'bold'
      }
    }
  ],
  [],
  headers.map(header => ({
    value: header.label,
    rowSpan: header.children?.length ? 1 : 2,
    colSpan: header.children?.length || 1,
    styles: { ...headerStyles, cellWidth: header.cellWidth }
  })),
  flatten(
    headers
      .filter(header => header.children !== undefined)
      .map(header =>
        header.children!.map(child => ({
          value: child,
          styles: headerStyles
        }))
      )
  ),
  ...generateCounteragentRows(report.counteragents)
];
