import { generateActionCrud } from 'src/components/Guards';
import { MAIN_MENU, NAV_BAR_HEADERS } from 'src/constants';
import { getDateDifference } from 'src/utils/date';

export const listBreadcrumbs = [
  {
    text: MAIN_MENU.asset,
    url: '/asset'
  }
];

export const pageBreadcrumbs = [
  ...listBreadcrumbs,
  {
    text: NAV_BAR_HEADERS.uninstallation,
    url: '/asset/uninstallation'
  }
];

export const MAX_DAYS_AFTER_UNINSTALLATION = 40;
export const TOGGLE_DISABLED_TEXT = `Документ зафіксовано понад ${MAX_DAYS_AFTER_UNINSTALLATION} днів тому. Редагування заборонене.`;
export const isToggleDateRestricted = (date: string) =>
  getDateDifference(date) > MAX_DAYS_AFTER_UNINSTALLATION;

export const crudActions = generateActionCrud(
  {
    module: 'assets',
    sub: 'uninstallation'
  },
  ['download', 'resendDocument', 'uninstallationAllowEditing']
);
export enum UninstallationStatusEnum {
  Draft = 0,
  Fixed = 1
}

export const uninstallationStatusEnumStatusMap = {
  [UninstallationStatusEnum.Draft]: 'Draft',
  [UninstallationStatusEnum.Fixed]: 'Fixed'
};

export const UNINSTALLATION_STEPS = ['Демонтаж', 'Обладнання'];

export const uninstallationDocumentPdf = [
  {
    label: 'Дата та час збереження документа',
    value: 'modifiedAtUtc'
  },
  {
    label: 'Виконавець',
    value: 'uninstallingEmployeeFullName'
  },
  {
    label: 'Хто прийняв',
    value: 'acceptingEmployeeFullName'
  },
  {
    label: 'Склад',
    value: 'counteragentHolderFullName'
  },
  {
    label: 'Адреса складу',
    value: 'counteragentHolderAddress'
  },
  {
    label: 'Примітка',
    value: 'notes'
  }
];

export const uninstallationMobileColumnsPdf = [
  {
    label: '№',
    value: 'index'
  },
  {
    label: 'Штрихкод',
    value: 'barcode'
  },
  {
    label: 'Назва',
    value: 'equipmentName'
  },
  {
    label: 'Модель',
    value: 'productType'
  },
  {
    label: 'Виробник',
    value: 'manufacturer'
  },
  {
    label: 'IMEI',
    value: 'imei'
  },
  {
    label: 'Запчастини',
    value: 'spareParts'
  },
  {
    label: 'Стан',
    value: 'equipmentStateName'
  },
  {
    label: 'Підрозділ',
    value: 'uninstalledFromCounteragentFullName'
  },
  {
    label: 'Тип ТЗ',
    value: 'vehicleType'
  },
  {
    label: 'Модель ТЗ',
    value: 'vehicleModel'
  },
  {
    label: 'Реєстраційний номер ТЗ',
    value: 'licensePlate'
  }
];

export const uninstallationStationaryColumnsPdf = [
  {
    label: '№',
    value: 'index'
  },
  {
    label: 'Штрихкод',
    value: 'barcode'
  },
  {
    label: 'Назва',
    value: 'equipmentName'
  },
  {
    label: 'Модель',
    value: 'productType'
  },
  {
    label: 'Виробник',
    value: 'manufacturer'
  },
  {
    label: 'IMEI',
    value: 'imei'
  },
  {
    label: 'Запчастини',
    value: 'spareParts'
  },
  {
    label: 'Стан',
    value: 'equipmentStateName'
  },
  {
    label: 'Підрозділ',
    value: 'uninstalledFromCounteragentFullName'
  },
  {
    label: 'Адреса підрозділу',
    value: 'address'
  },
  {
    label: 'Працівник - відповідальна особа',
    value: 'responsibleEmployeeFullName'
  }
];

export const uninstallationFieldsJson = [
  'documentNumber',
  'uninstallationDate',
  'acceptingEmployeeFullName',
  'uninstallingEmployeeFullName',
  'counteragentHolderFullName',
  'notes',
  'modifiedAtUtc',
  {
    label: 'equipments',
    fields: [
      'equipmentGroup',
      'barcode',
      'equipmentName',
      'productType',
      'manufacturer',
      'counteragent',
      'vehicleType',
      'vehicleModel',
      'spareParts',
      'licensePlate',
      'address',
      'equipmentStateName',
      'responsibleEmployeeFullName'
    ]
  }
];

export const UNINSTALLATION_ERROR_MESSAGES: Record<string, string> = {
  "It's forbidden to uninstall equipment from different vehicles in the same document.":
    'Обладнання, що демонтується, повинно знаходитись на одному транспортному засобі',
  "It's forbidden to uninstall equipment from different counteragents in the same document.":
    'Обладнання, що демонтується, повинно знаходитись на одному підрозділі',
  "It's forbidden to uninstall equipment from different employee in the same document.":
    'Обладнання, що демонтується, повинно знаходитись у одної відповідальної особи',
  "It's forbidden to uninstall equipment with different equipment group in the same document.":
    'Обладнання, що демонтується, повинно мати однакову групу обладнання',
  'Fix document with already moved equipments is forbidden.':
    'Неможливо зафіксувати документ. Детальна частина документа не актуальна'
};
