import { SheetDimensions } from '../../../../../../../utils/downloadXlsxFileFromTable';

const dimensions: SheetDimensions = {
  cell: {
    heights: {
      A2: 15
    }
  },
  column: {
    widths: {
      C6: 80,
      E6: 80,
      F6: 80,
      G6: 80,
      H6: 80,
      I6: 80
    }
  }
};

export default dimensions;
